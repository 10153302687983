import { Play } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';

const HomeCarousel = ({ images, setActiveVideoIndex, setactivePlaylist, videos, setshowModal, from, setplaylisttype, setfrom }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);

    async function openModal() {
        // if (from === "interactive") return;
        // console.log(from)
        setfrom(from)
        setActiveVideoIndex(activeIndex);
        setactivePlaylist(videos);
        setshowModal(true);
        setplaylisttype(from);
    }

    return (
        <>
            {images.map((image, index) => {
                return (
                    <div className="" key={index} onClick={() => openModal()}>
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
                            <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                <Play size={34} color="white" weight='fill' />
                            </div>
                        </div>
                        <img
                            src={image?.thumbnail ?? require(`../assets/images/homepage/${image}`)}
                            alt={`${index + 1}`}
                            className={`absolute top-0 left-0 right-0 rounded-2xl h-[80%] bottom-0 m-auto transition-transform cursor-pointer duration-500 ${index === activeIndex
                                ? 'z-30 scale-110'
                                : 'z-10 ' + (index === activeIndex - 1 || (activeIndex === 0 && index === images.length - 1)
                                    ? '!-left-16 '
                                    : index === activeIndex + 1 || (activeIndex === images.length - 1 && index === 0)
                                        ? ' !left-16  '
                                        : '')
                                }`}
                        />
                    </div>
                );
            })
            }
        </>
    );
};

export default HomeCarousel;
