import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Influencer from './pages/influencer'
import InfluencerApplyNow from './pages/influencerApplyNow'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './components/ScrollToTop'

export default function Router() {

  return (
    <>
      <ToastContainer toastStyle={{ backgroundColor: "#2a2a2a", color: "white" }} />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<Influencer />} />
          <Route path="/applynow" element={<InfluencerApplyNow />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}


