import React from "react";

export default function Modalcard({ children, close, zindex }) {
  return (
    <div className="fixed top-0 left-0 z-[150] flex h-screen w-full items-center justify-center select-none">
      <div
        className=" absolute h-screen w-full cursor-pointer bg-[#121212e1] bg-opacity-80"
        onClick={() => close(false)}
      ></div>
      <div className={"z-50"}>{children}</div>
    </div>
  );
}
