import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ArrowCircleRight, } from '@phosphor-icons/react'
import HomeCarousel from '../components/HomeCarousel';
import PlaylistFeed from '../components/playlistFeed';
import Modalcard from '../components/modalcard';
import axios from 'axios';
import ReactVisibilitySensor from 'react-visibility-sensor';

export default function Influencer() {
    const navigate = useNavigate();
    const [shopablePlaylist, setshopablePlaylist] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activePlaylist, setactivePlaylist] = useState([]);
    const [showModal, setshowModal] = useState(false);
    const [playlisttype, setplaylisttype] = useState("");
    const [from, setfrom] = useState("");
    const shopableId = [
        "review_IsFQM_aANArl2OckzJTBy0Oj",
        "review_EyjOjl9FFmrr4XI2409ywIQZ",
        "review_fnXsi9KZq_3bj2r3yocinW-X",
        "review_97j-KmWhFJR1Hsl_DYWzWfEQ",
        "review_Ds9IkFQ12ikIa0diSpdM-EYz",
    ];
    const Remoteimages = ["fully remote video 1.jpg", "fully remote 2.jpg", "fully remote 3.jpg"];
    let apiResponses = [];
    const shoppableVideos = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksH%2Freview_2VFPiDUE6nKcoL8Y4GyfzpvVR.mp4?alt=media&token=4cf281a9-4410-45de-ae03-456d2833e074",
            poster: "",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksL%2Freview_TreR006T-trgGiTv_YHQlNIRU.mp4?alt=media&token=41cc3b3a-3696-457d-932d-888a7327eedb",
            poster: "",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksJ%2Freview_wiMvuHlg34OMC1ip0OvtgRf0Y.mp4?alt=media&token=a399bb28-8b5f-46ae-98d1-8f7ba68603b1",
            poster: "",
        },
    ];
    const remoteVideos = [
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/review%2Freview_j6o-XOWLsoK4mm8TLL.mp4?generation=1673599840306806&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/thumbnails%2Freview_j6o-XOWLsoK4mm8TLL.jpg?alt=media&token=0d0fb15a-fc83-442f-aaea-bba9aed29e92",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/review%2Freview_rxKVVbyh81pRI0iMN2.mp4?generation=1673535034887462&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/thumbnails%2Freview_rxKVVbyh81pRI0iMN2.jpg?alt=media&amp;token=f8f15d40-85fb-440f-a1da-87b73bf7bb58",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/review%2Freview_u0VcQygRlhvvRyZvSg.mp4?alt=media&token=1a5f020b-ccc6-4e31-b969-5069a5406e79",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/thumbnails%2Freview_u0VcQygRlhvvRyZvSg.jpg?alt=media&token=e26eae82-7bb2-4d02-8c4f-aaaeaf45a717",
        },
    ];
    useEffect(() => {
        fetchAllData(shopableId);
    }, []);
    async function fetchAllData(arr) {
        const promises = arr.map((obj) => getReviewsFlicks(obj));
        const responses = await Promise.all(promises);
        apiResponses.push(...responses);
        setshopablePlaylist(apiResponses);
    }
    async function getReviewsFlicks(id) {
        let headersList = {
            Accept: "*/*",
            Authorization: "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
        };

        let flickReqOptions = {
            url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksById/${id}`,
            method: "GET",
            headers: headersList,
        };
        let response = await axios.request(flickReqOptions);
        let data = response.data.data;
        return data;
    }

    return (
        <div className='min-h-screen bg-black w-full text-white'>
            {showModal === true && (
                <>
                    <Modalcard close={setshowModal}>
                        {from === "interactive" ? (
                            <iframe
                                src="/embed/faqplayer/19j0i6o1rZsQsNQxDSFa/hR0WeL0M0FMv2HUa0gwM"
                                title="faqplayer"
                                // height="532"
                                // width="300"
                                className="w-full md:w-[380px] aspect-[9/16]"
                                style={{ borderRadius: "0.75rem" }}></iframe>
                        ) : (
                            <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                                <div className="w-full h-full rounded overflow-y-auto  snap-y snap-mandatory scrollbar" id="zero-scrollbar">
                                    <style>
                                        {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
                                    </style>
                                    {playlisttype === "reviews" ? (
                                        <>
                                            {activePlaylist?.map((video, index) => (
                                                <PlaylistFeed data={video} key={index} keyid={`playlist${index}`} displayscroll={true} />
                                            ))}
                                        </>
                                    ) : (
                                        <PlaylistFeed
                                            data={from === "remote" ? remoteVideos?.[activeIndex] : shopablePlaylist?.[activeIndex]}
                                            displayscroll={false}
                                            showproduct={from === "remote" ? false : true}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Modalcard>
                </>
            )}
            <div className="h-16 w-full flex items-center justify-between px-6">
                <div className="">
                    <Link to="/">
                        <img loading="lazy" src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fmy%20revue%20elite.svg?alt=media&token=1b4dd481-9a7f-48f5-85f4-5ffb3bc030a1"} alt="logo" className="h-[40px] md:h-[62px] w-auto" />
                    </Link>
                </div>
                <div className="hidden lg:flex gap-10 ">
                    {/* <button className="min-w-[180px] tracking-wide">What to do</button>
                    <button className="min-w-[180px] tracking-wide">Eligibility</button>
                    <button className="min-w-[180px] tracking-wide">Rewards</button> */}
                </div>
                <div className="">
                    <button onClick={() => navigate("/applynow")} className="text-white rounded-7 bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[150px] rounded-md">Apply Now</button>
                </div>
            </div>
            <div className="h-auto lg:h-[calc(100vh-64px)] w-full relative">
                <div className="absolute top-0 left-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FIntersect.svg?alt=media&token=a532282b-dc96-4b8f-9098-12331533d89c" alt="" className="max-w-[140px]" />
                </div>
                <div className="absolute bottom-0 left-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FVector%205%20bottom%20left.svg?alt=media&token=e8057ea7-98f5-4cce-a0b2-7f64c53dd08e" alt="" className="max-w-[150px]" />
                </div>
                <div className="absolute top-0 right-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FIntersect%20top%20right.svg?alt=media&token=7627081b-dd47-43e4-aeeb-c81fd3b41b23" alt="" className="max-w-[500px]" />
                </div>
                <div className="absolute bottom-0 right-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FIntersect%20bottom.svg?alt=media&token=45c1c1b0-d1d1-43be-9804-94e9c9c314ac" alt="" className="" />
                </div>
                <div className="grid md:grid-cols-2 lg:absolute h-full w-full z-50 p-5 md:p-0">
                    <div className="w-full md:pl-20 flex flex-col justify-center">
                        <div className="flex items-center gap-3">
                            {/* <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fsquare%20logo.png?alt=media&token=b58d74f0-785a-4478-aa9e-69ada2105bab" alt="" className="h-8 md:h-auto" /> */}
                            <p className="text-xl text-white">MyRevue Presents</p>
                        </div>
                        <div className="">
                            <p className="hidden md:block text-[100px] -ml-1 leading-[100px] font-semibold bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">India's Next <br /> Sensation</p>
                            <p className="md:hidden block text-[50px] leading-[50px] font-semibold bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">India's Next <br /> Sensation</p>
                            <p className="text-white tracking-wide my-3 text-xl hidden md:block">Discovering The Next Generation of <br /> India’s Greatest Influencers .</p>
                            <p className="text-white tracking-wide my-3 text-xl block md:hidden">Discovering The Next Generation of  India’s Greatest Influencers .</p>
                            <p className="text-white text-xl">Are You Ready ?</p>
                            <button onClick={() => navigate("/applynow")} className="text-white  shadow-[#12121299] mt-3 rounded-md bg-gradient-to-b from-pink-500 to-purple-700 shadow-md h-11 min-w-[170px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-center mt-10 md:mt-0">
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FGroup%20629.png?alt=media&token=d07df66c-bdf3-4fd6-bd55-43d71fe11e55" alt="" className="max-h-[85vh]" />
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#282828] bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fpattern.png?alt=media&token=f8556b6a-9c64-40e3-9a77-79e1fc3256a8')]">
                <div className="grid md:grid-cols-2">
                    <div className="w-full">
                        <p className="text-white text-4xl hidden md:block md:text-5xl text-center md:text-left w-full font-semibold tracking-wide leading-tight">Do you have what it <br />needs to become <br />the next big <br /> sensation?</p>
                        <p className="text-white text-4xl block md:hidden md:text-5xl text-center md:text-left w-full font-semibold tracking-wide leading-tight">Do you have what it needs to become the next big sensation?</p>
                    </div>
                    <div className="w-full mt-4 md:mt-0">
                        <p className="text-white md:text-xl text-center md:text-right">The MyRevue Creator Program is a prestigious initiative designed to nurture talented young individuals into becoming the foremost content creators in India. Supported by a team of influential figures in the industry, we are actively searching for exceptional content creators who have the potential to redefine the realm of content creation.</p>
                        <div className="flex justify-center md:justify-end">
                            <button onClick={() => navigate("/applynow")} className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#010101] grid md:grid-cols-2">
                <div className="order-2 md:order-1 mt-8 md:mt-0">
                    <div className="flex justify-center w-full ">
                        <ReactVisibilitySensor partialVisibility once offset={{ top: -20 }}>
                            {({ isVisible }) => (
                                <div className={`relative w-max  ${isVisible ? "slide-to-right" : ""}`}>
                                    <img
                                        loading="lazy"
                                        className={` rounded-xl opacity-0 h-[80%]`}
                                        src={"https://brand.myrevue.app/static/media/Interactive%20video%201.17e1b02ef12d9d2ce3a3.png"}
                                        alt="1"
                                    />
                                    <div className="absolute lg:hidden bottom-12 left-1/2 -translate-x-1/2 z-50 w-full">
                                        <div className="py-2 px-10 text-black drop-shadow-lg shadow-black font-semibold w-fit mx-auto rounded-lg bg-[#EDF7ED]">
                                            <p className="">Click for preview</p>
                                        </div>
                                    </div>
                                    <HomeCarousel
                                        images={shopablePlaylist}
                                        setActiveVideoIndex={setActiveIndex}
                                        setactivePlaylist={setactivePlaylist}
                                        videos={shoppableVideos}
                                        setshowModal={setshowModal}
                                        from="shopable"
                                        setplaylisttype={setplaylisttype}
                                        setfrom={setfrom}
                                    />
                                </div>
                            )}
                        </ReactVisibilitySensor>
                    </div>
                </div>
                <div className="order-1 md:order-2 flex flex-col justify-center space-y-2">
                    <p className="text-white md:text-right text-center text-4xl md:text-[70px] font-semibold">Who’s it for?</p>
                    <p className="md:text-right text-center text-xl md:text-3xl md:pt-2 font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">Everyone!</p>
                    <p className="text-white md:text-right hidden lg:block text-center text-2xl tracking-wide">Whether you're new to this or have been at it <br /> for a while, whether content creation is your<br /> full-time gig or a part-time pursuit alongside <br /> your day job, all it takes is your passion for<br /> content creation!</p>
                    <p className="text-white lg:text-right block lg:hidden text-center text-lg">Whether you're new to this or have been at it  for a while, whether content creation is your full-time gig or a part-time pursuit alongside  your day job, all it takes is your passion for content creation!</p>
                    <div className="flex justify-center md:justify-end">
                        <button onClick={() => navigate("/applynow")} className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#161616] ">
                <div className="md:flex items-center justify-between">
                    <div className="text-white text-4xl md:text-5xl hidden md:block font-semibold leading-tight">So, what do you have <br /> to do?</div>
                    <div className="text-white text-4xl md:text-5xl block md:hidden text-center font-semibold leading-tight">So, what do you have to do?</div>
                    <div className="flex justify-center md:justify-start">
                        <button onClick={() => navigate("/applynow")} className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                    </div>
                </div>
                <div className="w-full rounded-xl md:rounded-3xl py-10 bg-gradient-to-r from-[#A24DAD] via-[#A84781] to-[#D24E7C] p-5 grid md:grid-cols-3 gap-10 md:0 divide-white mt-5">
                    <div className="w-full flex flex-col items-center justify-center">
                        <p className="text-white text-4xl md:text-5xl font-semibold">Step 1</p>
                        <p className="text-white mt-3 text-base md:text-lg text-center px-10">Apply now, fill the form and pay the registration fee.</p>
                    </div>
                    <div className="w-full border-t md:border-t-0 pt-5 md:pt-0 md:border-l border-white flex flex-col items-center justify-center">
                        <p className="text-white text-4xl md:text-5xl font-semibold">Step 2</p>
                        <p className="text-white mt-3 text-base md:text-lg text-center px-10">Follow the steps to create a 30 sec reel given in the email we'll send you.</p>
                    </div>
                    <div className="w-full border-t md:border-t-0 pt-5 md:pt-0 md:border-l border-white flex flex-col items-center justify-center">
                        <p className="text-white text-4xl md:text-5xl font-semibold">Step 3</p>
                        <p className="text-white mt-3 text-base md:text-lg text-center px-10">Post the reel and tag myrevueapp to it.</p>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#010101] ">
                <div className="bg-[#282828] grid md:grid-cols-2 py-5 md:py-12  px-5 md:px-24 rounded-xl md:rounded-3xl">
                    <div className="w-full relative">
                        <p className="text-white text-4xl font-medium pb-2">Wondering if you’re eligible for it?</p>
                        <p className=" text-xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">
                            To be eligible you need to...
                        </p>
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FTo%20be%20eligible%20you%20need%20to....png?alt=media&token=5301f5ff-223b-47e1-a80f-3b5af0fee60b" alt="wondering" className="w-full mt-3 max-w-[550px] h-auto" />
                    </div>
                    <div className="space-y-2 flex flex-col justify-center mt-10 md:mt-0">
                        <div className="">
                            <p className="text-white min-h-[60px] py-3 px-8 text-sm md:text-lg md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">Be 18 years or older</p>
                        </div>
                        <div className="">
                            <p className="text-white min-h-[60px] py-3 px-8 text-sm md:text-lg md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">A public social media profile.</p>
                        </div>
                        <div className="">
                            <p className="text-white min-h-[60px] py-3 px-8 text-sm md:text-lg md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">An engaged social media following on at least one platform.</p>
                        </div>
                        <div className="">
                            <p className="text-white min-h-[60px] py-3 px-8 text-sm md:text-lg md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">High-quality, shoppable content posted regularly (high-performing Creators share content daily!)</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <button onClick={() => navigate("/applynow")} className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                        </div>
                        <div className="">
                            <p className="text-[#AFAFAF] text-center mt-3 tracking-wide">*Can be of any niche</p>
                        </div>
                    </div>
                </div>
                <div className="px-0 md:px-12 py-5 md:py-12">
                    <p className="text-white text-5xl font-semibold">What to know what's in it for you ?</p>
                    <div className="flex flex-col md:flex-row justify-between items-center gap-10 mt-4 md:mt-0">
                        <div>
                            <div className="">
                                <p className="text-white"><span className="text-xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">MyRevue</span> was created by a fellow content creator, with a focus on serving the creator community. We truly understand what distinguishes exceptional influencers across all categories. Our dedicated teams are here to provide hands-on strategic guidance, coupled with the latest digital tools in the industry, to help you make the most out of your content monetization efforts and cultivate long-lasting brand partnerships.</p>
                            </div>
                            <div className="flex-1 w-full border border-white rounded-3xl mt-5 p-5 space-y-6">
                                <div className="flex items-center gap-5">
                                    <div className="">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                    </div>
                                    <div className="flex-1 w-full">
                                        <p className="text-white tracking-wide">Participate in the Monthly Leaderboard Contest for a chance to secure the ₹10K Cash Prize and the coveted MyRevue Sensation Trophy 🏆</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-5">
                                    <div className="">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                    </div>
                                    <div className="flex-1 w-full">
                                        <p className="text-white tracking-wide">Enjoy opportunities for brand collaborations valued between ₹50K to ₹100K each month 🤝</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-5">
                                    <div className="">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                    </div>
                                    <div className="flex-1 w-full">
                                        <p className="text-white tracking-wide">Gain entry to exclusive community events with a combined worth of ₹25K 🎟️</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-5">
                                    <div className="">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                    </div>
                                    <div className="flex-1 w-full">
                                        <p className="text-white tracking-wide">Receive complimentary goodies from renowned brands, totaling ₹7K in value 💰</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-5">
                                    <div className="">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                    </div>
                                    <div className="flex-1 w-full">
                                        <p className="text-white tracking-wide">Access training sessions conducted by industry experts, valued at ₹50K 🧑🏻‍💻</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full max-w-[450px]">
                            <div className="flex justify-center w-full">
                                <ReactVisibilitySensor partialVisibility once offset={{ top: -20 }}>
                                    {({ isVisible }) => (
                                        <div className={`relative md:w-max md:ml-20 ${isVisible ? "slide-to-right" : ""}`}>
                                            <div className="absolute lg:hidden bottom-12 left-1/2 -translate-x-1/2 z-50 w-full">
                                                <div className="py-2 px-10 text-black drop-shadow-lg shadow-black font-semibold w-fit mx-auto rounded-lg bg-[#EDF7ED]">
                                                    <p className="">Click for preview</p>
                                                </div>
                                            </div>
                                            <img
                                                loading="lazy"
                                                className={` rounded-xl opacity-0 h-[80%]`}
                                                src={"https://brand.myrevue.app/static/media/Interactive%20video%201.17e1b02ef12d9d2ce3a3.png"}
                                                alt="1"
                                            />
                                            <HomeCarousel
                                                images={Remoteimages}
                                                setActiveVideoIndex={setActiveIndex}
                                                setactivePlaylist={setactivePlaylist}
                                                videos={remoteVideos}
                                                setshowModal={setshowModal}
                                                from="remote"
                                                setplaylisttype={setplaylisttype}
                                                setfrom={setfrom}
                                            />
                                        </div>
                                    )}
                                </ReactVisibilitySensor>
                            </div>                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full">
                <div className="bg-[#161616] rounded-xl px-5 p-10 bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fpattern%202.png?alt=media&token=3ade6d45-b734-456a-8593-751f57b0e46d')]">
                    <div className="">
                        <p className="text-white text-4xl md:text-5xl font-semibold text-center">Why Apply Now ??</p>
                    </div>
                    <div className="flex flex-col md:flex-row items-start justify-center mt-20 md:mt-24 gap-20 md:gap-10">
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCodesandboxLogo.png?alt=media&token=2347dc5a-66e3-47cd-a38e-4dbb5e6b519c" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className="mt-5 text-2xl font-medium text-white">Demand Growth</p>
                            <p className="text-white mt-3 text-lg tracking-wide text-center"> Influencer industry <br /> expands 6-8% yearly.</p>
                        </div>
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FPiggyBank.png?alt=media&token=f22cc603-4a3a-46a1-9f5a-9e43e9210a22" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className=" mt-5 text-2xl font-medium text-white">Earnings Range</p>
                            <p className="text-white mt-3 text-lg tracking-wide text-center">upto ₹5L per post, higher <br /> for top influencers.</p>
                        </div>
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FProjectorScreenChart.png?alt=media&token=7768d9d3-7c42-4178-b9e4-e1f1c90fd27d" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className=" mt-5 text-2xl font-medium text-white">Creative Showcase</p>
                            <p className="text-white mt-3 text-lg tracking-wide text-center">75% express uniqueness <br /> through platforms.</p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-start justify-center mt-20 gap-20 md:gap-10">
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FWifiHigh.png?alt=media&token=f3f0d24c-2484-44b8-9ea6-7240dd2577bc" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className=" mt-5 text-2xl font-medium text-white">Networking Benefits</p>
                            <p className="text-white mt-3 text-lg tracking-wide text-center">80% form valuable <br />industry connections.</p>
                        </div>
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FUsersFour.png?alt=media&token=82a3523c-1ea2-462f-98a8-fee4534fa013" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className=" mt-5 text-2xl font-medium text-white">Positive Influence</p>
                            <p className="text-white mt-3 text-lg tracking-wide text-center">65% believe they impact <br /> audience decisions.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="p-5 md:p-10 w-full bg-[#010101] ">
                <div className="text-3xl font-semibold leading-tight text-center">Why Choose The MyRevue Creator Program?</div>
                <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-10 mt-10">
                    <div className="max-w-[430px] w-full border border-white bg-[#1E1E1E] rounded-xl p-5">
                        <p className="text-white text-xl font-medium">Over</p>
                        <p className=" mt-2 text-4xl md:text-6xl font-semibold text-[#F92147]">23000+</p>
                        <div className="flex justify-between items-center mt-10">
                            <p className="text-white text-xl font-medium">Vast Creator <br />Network</p>
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fcommunity.png?alt=media&token=ed7e3e4f-759a-4256-895b-6b4648c00919" alt="" className="" />
                        </div>
                    </div>
                    <div className="max-w-[430px] w-full border border-white bg-[#1E1E1E] rounded-xl p-5">
                        <p className="text-white text-xl font-medium">Partnered with</p>
                        <p className=" mt-2 text-4xl md:text-6xl font-semibold text-[#F0DE7D]">50+ Brands</p>
                        <div className="flex justify-between items-center mt-10">
                            <p className="text-white text-xl font-medium">Strong Brand<br /> Engagement</p>
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fgraph%20up.png?alt=media&token=00e772a4-e299-4b95-a919-1893cf40c6e2" alt="" className="" />
                        </div>
                    </div>
                    <div className="max-w-[430px] w-full border border-white bg-[#1E1E1E] rounded-xl p-5">
                        <p className="text-white text-xl font-medium"> Released an amount of</p>
                        <p className=" mt-2 text-4xl md:text-6xl font-semibold text-[#B3F7A1]">₹10 Lakhs</p>
                        <div className="flex justify-between items-center mt-10">
                            <p className="text-white text-xl font-medium">Substantial <br />Funding</p>
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fdollatr.png?alt=media&token=7e6cd38e-edc9-442d-a5f2-d9fbbc13e992" alt="" className="" />
                        </div>
                    </div>
                </div>
                <div className="text-xl w-full flex items-end justify-center tracking-wide mt-5">
                    <p className="text-white">Impressive Social Reach: In-house Facebook & Instagram properties reach <span className="text-3xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent"> 10 </span>  <span className="bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">million</span></p>
                </div>
                <div className="w-full border border-white rounded-3xl p-4 md:p-8 mt-8 md:mt-16">
                    <p className="text-white text-4xl md:text-5xl font-semibold text-center">Become The India’s next Sensation With <br /> <span className="bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">MyRevue</span></p>
                    <div className="flex items-center justify-center">
                        <button onClick={() => navigate("/applynow")} className="text-white mt-6 rounded-full bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-12 min-w-[200px] px-4 md:px-12 flex  items-center justify-center md:gap-3">Apply now to be the next sensation <ArrowCircleRight size={28} color="#ffffff" className='hidden md:block' /></button>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#252525] p-4 md:p-8 mt-5 flex flex-col md:flex-row justify-between items-center">
                <p className="text-white text-4xl md:text-6xl font-semibold text-left leading-snug hidden md:block">1538 creators already  registered,<br /> what are you  waiting for ?</p>
                <p className="text-white text-4xl md:text-5xl font-semibold text-center leading-snug md:hidden block">1538 creators already registered, what are you  waiting for ?</p>
                <div className="flex items-center justify-center">
                    <button onClick={() => navigate("/applynow")} className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-12 min-w-[130px] px-12  flex items-center justify-center gap-3">Apply now</button>
                </div>
            </div>
        </div>
    )
}
