import { Checks, CircleNotch, ShieldSlash } from '@phosphor-icons/react'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import { db } from '../firebase';

export default function InfluencerApplyNow() {
    const [gender, setGender] = useState('male');
    const [brandCollab, setBrandCollab] = useState(false);
    const [niche, setNiche] = useState(null);
    const [applied, setApplied] = useState(false);
    const [paymentLinkDetails, setpaymentLinkDetails] = useState(null);
    const [fetchStatus, setFetchStatus] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const navigate = useNavigate();
    const search = useLocation().search
    const searchParams = new URLSearchParams(search);

    const [docId, setDocId] = useState(null);

    let fullName = useRef();
    let email = useRef();
    let instagramUrl = useRef();
    let totalFollowers = useRef();
    let countryCode = useRef();
    let phoneNumber = useRef();
    let brandNames = useRef();
    let age = useRef();
    let profession = useRef();



    useEffect(() => {
        if (fetchStatus === true) {
            const intervalId = setInterval(() => {
                fetchPaymentStatus()
            }, 10000);
            return () => clearInterval(intervalId)
        }

    }, [fetchStatus])


    async function submit() {
        const document = {
            name: fullName.current.value,
            email: email.current.value,
            instagram: instagramUrl.current.value,
            totalFollowers: totalFollowers.current.value,
            phone: "+" + countryCode.current.value + phoneNumber.current.value,
            brands: brandNames.current.value,
            age: age.current.value,
            profession: profession.current.value,
            gender: gender,
            niche: niche,
            brandCollab: brandCollab,
            paymentStatus: false
        }
        const application = await addDoc(collection(db, "createrApplication"), document);
        setDocId(application.id)
        if (!document.name || !document.email || !document.instagram || !document.totalFollowers || document.phone.length < 12 || !document.brands || !document.age || !document.profession || !document.niche) {
            toast.warning("Please fill all details", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="red" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return
        }
        setPaymentLoading(true)
        await createPayment(document)

    }
    async function fetchPaymentStatus() {
        const paymentStatusData = await axios.get(`https://prod-paymentapi-zscu3untuq-el.a.run.app/verifyPaymentToken/${paymentLinkDetails.id}`);
        if (paymentStatusData?.data !== "") {
            if (paymentStatusData?.data?.payment_token?.status === "paid") {
                await createDocument();
                return
            }
        }
    }

    async function createDocument() {
        // await addDoc(collection(db, "createrApplication"), document);
        await updateDoc(doc(db, "createrApplication", docId), { paymentStatus: true })
        toast.success("You have successfully applied.", {
            position: "top-right",
            icon: <Checks size={32} color="green" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work"
        });
        setApplied(true);
        setPaymentLoading(false);
        setFetchStatus(false);
        navigate("/applynow?success=true")
    }
    async function createPayment(document) {
        let headersList = {
            "Accept": "*/*",
            "Content-Type": "application/json"
        }

        let bodyContent = {
            "name": document?.name,
            "email": document?.email,
            "phoneNumber": phoneNumber.current.value,
            "invoiceId": Date.now(),
            "currency": "INR",
            "amount": 999,
        };

        let reqOptions = {
            url: "https://prod-paymentapi-zscu3untuq-el.a.run.app/createPaymentToken",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        }
        try {
            let response = await axios.request(reqOptions);
            setpaymentLinkDetails(response.data);
            triggerLayer(response.data)
            setFetchStatus(true);

        } catch (error) {
            toast.warning(error.message, {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
        }
        function triggerLayer(data) {
            window.Layer.checkout(
                {
                    token: data?.id,
                    // accesskey: "2ee70b30-b33f-11ed-8323-af62a19e9aa0",
                    accesskey: "785ad3c0-e970-11ed-aa1c-7161138d8d0f",
                },
                function (response) {
                    if (response?.status === 'failed') {
                        toast.warning('Something wrong happend, please fill details again to proceed', {
                            position: "top-right",
                            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                            bodyClassName: "text-sm tracking-wide font-work"
                        });
                        setFetchStatus(false);
                        setPaymentLoading(false)
                        return;
                    }
                    if (response?.status === 'pending') {
                        toast.warning('Payment is in pending, if amount is deducted from bank, it will get refunded within 24-48 hours.', {
                            position: "top-right",
                            // theme: "dark",
                            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                            bodyClassName: "text-sm tracking-wide font-work"
                        });
                        setFetchStatus(false);
                        setPaymentLoading(false)
                        return;
                    }
                    if (response !== null || response.length > 0) {
                        if (response.payment_id !== undefined) {
                            return;
                        }
                    }
                },
                function (err) {
                    alert(err.message);
                    return;
                }
            );
        }


        // settab(3);

    }
    return (
        <div className='min-h-screen bg-black w-full'>
            <div className="h-16 w-full flex items-center justify-between px-6">
                <div className="">
                    <Link to="/">
                        <img loading="lazy" src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fmy%20revue%20elite.svg?alt=media&token=1b4dd481-9a7f-48f5-85f4-5ffb3bc030a1"} alt="logo" className="h-[40px] md:h-[62px] w-auto" />
                    </Link>
                </div>
                <div className="hidden lg:flex gap-10 ">

                </div>
                <div className="">
                </div>
            </div>
            <div className=" w-full bg-[#010101] ">
                <div className="w-full grid lg:grid-cols-3 gap-10 px-5 md:pl-10 md:pr-0">
                    {searchParams.get('success') === "true" ?
                        <div className="lg:col-span-2 w-full  bg-[#171717] rounded-xl p-5 flex flex-col items-center justify-center">
                            <p className="text-white text-center text-3xl font-medium tracking-wide">🎉 Thank you for applying in MyRevue creater program.</p>
                            <p className="text-white text-center tracking-wide mt-2">Our team will get in touch with you soon.</p>
                            <p className="text-white text-center tracking-wide mt-3 text-xs">*For any queries mail us on connect@myrevue.app</p>
                        </div>
                        :
                        <div className="lg:col-span-2 w-full  bg-[#171717] rounded-xl p-5">
                            <p className="text-white text-2xl font-semibold tracking-wide">Apply Now!!</p>
                            <div className="space-y-5 mt-10">
                                <div className="w-full">
                                    <input ref={fullName} type="text" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='Full Name' />
                                </div>
                                <div className="w-full flex flex-col md:flex-row items-center gap-3 lg:gap-10">
                                    <div className="text-white">Gender</div>
                                    <div className="w-fit bg-[#373735] h-11 rounded-full flex p-[4px]">
                                        <div onClick={() => setGender('male')} className={`transition-all cursor-pointer h-full flex items-center rounded-full px-10 ${gender === "male" ? "bg-white text-black" : "text-white"}`}>Male</div>
                                        <div onClick={() => setGender('female')} className={`transition-all cursor-pointer h-full flex items-center rounded-full px-10 ${gender === "female" ? "bg-white text-black" : "text-white"}`}>Female</div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <input ref={email} type="text" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='Email Address' />
                                </div>
                                <div className="w-full">
                                    <input ref={instagramUrl} type="text" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='Instagram Url' />
                                </div>
                                <div className="w-full">
                                    <input ref={totalFollowers} type="number" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='Total Followers' />
                                </div>
                                <div className="w-full flex gap-2">
                                    <input ref={countryCode} type="number" className=" text-white pb-2 w-12 bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" defaultValue={91} />
                                    <input
                                        ref={phoneNumber}
                                        type="tel"
                                        onChange={(event) => {
                                            const inputPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                            if (inputPhoneNumber.length <= 10) {
                                                phoneNumber.current.value = inputPhoneNumber; // Use .current to access the input field
                                            } else {
                                                phoneNumber.current.value = inputPhoneNumber.substring(0, 10); // Limit to 10 digits
                                            }
                                        }}
                                        maxLength={10}
                                        className="text-white pb-2 flex-1 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]"
                                        placeholder='Phone Number'
                                    />
                                </div>
                                <div className="w-full">
                                    <input ref={brandNames} type="text" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='5 favourite brand names' />
                                </div>
                                <div className="w-full">
                                    <div className="text-white">Niche</div>
                                    <div className=" mt-3 flex flex-wrap   gap-5">
                                        <div onClick={() => setNiche('fashion')} className={`transition-all cursor-pointer h-11 flex items-center rounded-full px-5 md:px-10 ${niche === "fashion" ? "bg-white text-black" : "bg-[#373735] text-white"}`}>Fashion</div>
                                        <div onClick={() => setNiche('cosmetics')} className={`transition-all cursor-pointer h-11 flex items-center rounded-full px-5 md:px-10 ${niche === "cosmetics" ? "bg-white text-black" : "bg-[#373735] text-white"}`}>Cosmetics</div>
                                        <div onClick={() => setNiche('lifestyle')} className={`transition-all cursor-pointer h-11 flex items-center rounded-full px-5 md:px-10 ${niche === "lifestyle" ? "bg-white text-black" : "bg-[#373735] text-white"}`}>Lifestyle</div>
                                        <div onClick={() => setNiche('food')} className={`transition-all cursor-pointer h-11 flex items-center rounded-full px-5 md:px-10 ${niche === "food" ? "bg-white text-black" : "bg-[#373735] text-white"}`}>Food</div>
                                        <div onClick={() => setNiche('travel')} className={`transition-all cursor-pointer h-11 flex items-center rounded-full px-5 md:px-10 ${niche === "travel" ? "bg-white text-black" : "bg-[#373735] text-white"}`}>Travel</div>
                                        <div onClick={() => setNiche('tech')} className={`transition-all cursor-pointer h-11 flex items-center rounded-full px-5 md:px-10 ${niche === "tech" ? "bg-white text-black" : "bg-[#373735] text-white"}`}>Tech</div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <input ref={age} type="text" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='Age' />
                                </div>
                                <div className="w-full">
                                    <input ref={profession} type="text" className=" text-white pb-2 w-full bg-transparent border-b border-white appearance-none outline-none placeholder:text-[#808080]" placeholder='Profession' />
                                </div>
                                <div className="w-full flex flex-col md:flex-row  items-center gap-3 lg:gap-10">
                                    <div className="text-white">Have you done any brand collaborations previously?</div>
                                    <div className="w-fit bg-[#373735] h-11 rounded-full flex p-[4px]">
                                        <div onClick={() => setBrandCollab(true)} className={`transition-all cursor-pointer h-full flex items-center rounded-full px-10 ${brandCollab === true ? "bg-white text-black" : "text-white"}`}>Yes</div>
                                        <div onClick={() => setBrandCollab(false)} className={`transition-all cursor-pointer h-full flex items-center rounded-full px-10 ${brandCollab === false ? "bg-white text-black" : "text-white"}`}>No</div>
                                    </div>
                                </div>
                                {applied === false ?
                                    <div className="mt-5 flex justify-center">
                                        <button onClick={() => paymentLoading ? null : submit()} className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-12 min-w-[130px] px-12  flex items-center justify-center gap-3">{paymentLoading ? <CircleNotch size={22} color='white' className='animate-spin' /> : "Pay  ₹999"}</button>
                                    </div>
                                    :
                                    <div className='flex items-center justify-center h-full'>
                                        <button disabled className="text-white mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-12 min-w-[130px] px-12  flex items-center justify-center gap-3">You have successfully applied.</button>
                                    </div>
                                }
                            </div>

                        </div>
                    }
                    <div className="w-full hidden lg:flex justify-end ">
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FGroup%20629.png?alt=media&token=d07df66c-bdf3-4fd6-bd55-43d71fe11e55" alt="" className="max-h-[800px] hidden lg:block" />
                    </div>
                </div>
            </div>
        </div>
    )
}
